<script>
import Layout from "../../layouts/store";
import { api } from "@/state/services";
import Product from "@/components/widgets/loading-product";
import Buy from "@/components/widgets/store-buy";

export default {
  locales: {
    pt: {
      'Category': 'Categoria',
      'Buy': 'Comprar',
    },
    es: {
      'Category': 'Categoría',
      'Buy': 'Comprar',
    }
  },
  components: {
    Layout,
    Product,
    Buy
  },
  data() {
    return {
      category: {
        errored: false,
        id: this.$route.params.id,
        name: 'CARREGANDO...',
      },

      product: {
        modal: false,

        id: null,
        variations: null,
        images: [],
        name: null,
        description: null
      },

      products: {
        loading: true,
        errored: false,
        empty: false,
        list: null,
      },
    };
  },
  methods: {
    getCategory() {
      api
        .get('store/categories/' + this.category.id)
        .then(response => {
          if (response.data.status=='success') {
            this.category.name = response.data.name

            this.getProducts()
          }
        })
        .catch(error => {
          this.category.errored = error
        })
    },
    getProducts() {
      this.products.loading = true
      this.products.list = null

      api
        .get('store/products/' + this.category.id)
        .then(response => {
          if (response.data.status=='success') {
            this.products.list = response.data.list
            this.products.loading = false
          } else {
            this.products.list = null
          }
        })
        .catch(error => {
          this.products.errored = error
          this.products.loading = false
        })
        .finally(() => {
          this.products.loading = false
        })
    },
    showProduct(product) {
      this.product.modal = true
      this.product.id = product.id
      this.product.variations = product.variations
      this.product.images = product.images
      this.product.name = product.name
      this.product.description = product.description
    }
  },
  mounted() {
    this.getCategory()
  },
  watch: {
    '$route.params.id': function (v) {
      this.category.id = v
      this.getCategory()
    }
  }
};
</script>

<template>
  <Layout ref="store">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ this.category.name }}</h4>
        </div>
      </div>
    </div>
    <div v-if="products.loading" class="row">
      <div class="col-md-6 col-lg-4">
        <div class="card card-h-fix">
          <div class="card-body text-center">
            <Product></Product>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="card card-h-fix">
          <div class="card-body text-center">
            <Product></Product>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!products.list || products.list && products.list.length == 0" class="card">
      <div class="card-body">
        <div class="text-center py-5">
          <i class="far fa-sad-cry font-size-24"></i><br>
          ainda não temos nenhum produto disponível nesta categoria
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-6 col-lg-4" v-for="(product,id) in products.list" :key="id">
        <div v-if="product.instock == 'yes'" >
          <div class="card card-h-fix">
            <div class="card-body text-center">
              <div class="mb-3">
                <b-carousel
                  style="margin:0 auto; max-width:350px;"
                  controls
                  indicators
                  fade
                  :interval="0">
                  <b-carousel-slide style="max-width:350px; min-height:200px" v-for="(image, i) in product.images" :key="i" :img-src="image"></b-carousel-slide>
                </b-carousel>
              </div>
              <div class="font-weight-bold text-dark">
                {{ product.name }}
                <button class="btn btn-link p-0 text-dark" @click="showProduct(product)">
                  <i class="bx bx-info-circle font-size-22"></i>
                </button>
              </div>
              <div v-if="$refs.store.$refs.config.store.type.price === 'show'" class="row justify-content-center mt-3">
                <div class="d-none col-6 text-center">
                  <h5 class="mb-0 font-size-16">{{ product.price.consumer | currency }}</h5>
                  <span class="align-self-center font-size-11 bg-soft-light rounded p-1 text-uppercase">Consumidor</span>
                </div>
                <div class="col-6 text-center">
                  <h5 class="mb-0 font-size-16">{{ product.price.affiliate | currency }}</h5>
                  <span class="align-self-center font-size-11 bg-soft-light rounded p-1 text-uppercase">Consultor</span>
                </div>
              </div>
              <Buy :id="product.id" :variations="product.variations" class="mt-3"></Buy>
              <div class="mt-3 d-flex justify-content-center text-uppercase">
                <span class="align-self-center font-size-13 bg-soft-dark px-1 mr-1">{{ product.points.unilevel }}</span>
                <span class="align-self-center font-size-11" style="line-height:10px;">Pontos de Bonificação</span>
              </div>
              <div class="mt-1 d-flex justify-content-center text-uppercase">
                <span class="align-self-center font-size-13 bg-soft-dark px-1 mr-1">{{ product.points.graduation }}</span>
                <span class="align-self-center font-size-11" style="line-height:10px;">Pontos de Premiação</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="card card-h-fix">
            <div class="card-body text-center">
              <div class="mb-3">
                <b-carousel
                  style="margin:0 auto; max-width:350px;"
                  controls
                  indicators
                  fade
                  :interval="0">
                  <b-carousel-slide style="max-width:350px; min-height:200px; filter: grayscale(100%);" v-for="(image, i) in product.images" :key="i" :img-src="image"></b-carousel-slide>
                </b-carousel>
              </div>
              <div class="font-weight-bold text-dark">
                {{ product.name }}
                <button class="btn btn-link p-0 text-dark" @click="showProduct(product)">
                  <i class="bx bx-info-circle font-size-22"></i>
                </button>
              </div>
              <div v-if="$refs.store.$refs.config.store.type.price === 'show'" class="row justify-content-center mt-3">
                <div class="d-none col-6 text-center">
                  <h5 class="mb-0 font-size-16">{{ product.price.consumer | currency }}</h5>
                  <span class="align-self-center font-size-11 bg-soft-light rounded p-1 text-uppercase">Consumidor</span>
                </div>
                <div class="col-6 text-center">
                  <h5 class="mb-0 font-size-16">{{ product.price.affiliate | currency }}</h5>
                  <span class="align-self-center font-size-11 bg-soft-light rounded p-1 text-uppercase">Consultor</span>
                </div>
              </div>
              <b-button disabled block class="mt-3">INDISPONÍVEL</b-button>
              <div class="mt-3 d-flex justify-content-center text-uppercase">
                <span class="align-self-center font-size-13 bg-soft-dark px-1 mr-1">{{ product.points.unilevel }}</span>
                <span class="align-self-center font-size-11" style="line-height:10px;">Pontos de Bonificação</span>
              </div>
              <div class="mt-1 d-flex justify-content-center text-uppercase">
                <span class="align-self-center font-size-13 bg-soft-dark px-1 mr-1">{{ product.points.graduation }}</span>
                <span class="align-self-center font-size-11" style="line-height:10px;">Pontos de Premiação</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="product.modal" hide-footer centered>
      <div class="mb-3">
        <b-carousel
          style="margin:0 auto; max-width:350px;"
          controls
          indicators
          fade
          :interval="0">
          <b-carousel-slide style="max-width:350px; min-height:200px" v-for="(image, i) in product.images" :key="i" :img-src="image"></b-carousel-slide>
        </b-carousel>
      </div>
      <h4 class="font-weight-bold mb-3">{{ product.name }}</h4>
      <div v-html="product.description"></div>
    </b-modal>
  </Layout>
</template>